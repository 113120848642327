import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import MainScreen from "../../components/services/main-screen"
import ServicesList from '../../components/services/services-list';
import Technologies from '../../components/page-parts/technologies';
import FormSection from '../../components/page-parts/form-section';

const Services = () => {

  const pageTitle = 'Услуги веб-разработки'
  const pageDescription = 'Предоставляем различные услуги в сфере веб-разработки. Разработка сайтов (лендингов, корпоративных, квизов, интернет-магазинов), веб-дизайн, маркетинг и реклама, интеграция с сервисами автоматизации бизнеса.'
  // const keywords = ''

  return (
    <Layout fixedHeader={true}>
      <Seo title={pageTitle} description={pageDescription} />
      <MainScreen />
      <ServicesList />
      <Technologies />
      <FormSection />
    </Layout>
  )
}

export default Services
