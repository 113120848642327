import React from 'react'
import { Box, Container, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import SiteDev from '../../svg/site-dev-svg.svg'
import AdvertImage from '../../svg/advert-svg.svg'
import DesignImage from '../../svg/design-svg.svg'
import AutomatImage from '../../svg/automat-svg.svg'

import ArrowRight from '../../svg/arrowRightDark.svg'

const useStyles = makeStyles((theme) => ({

  gridWrap: {
    padding: '2rem 0',

    '&.gray': {
      background: 'var(--font-color-transp)',
    },
  },

  serviceBox: {
    display: 'grid',
    gridTemplateAreas: `'image'
                        'info'`,
    gridTemplateColumns: '1fr',
    gridGap: '2rem',

    '& > *': {
      minWidth: 0,
    }
  },

  imageBox: {
    gridArea: 'image',
    display: 'flex',
    justifyContent: 'center',

    '& svg': {
      width: '300px',
      height: 'auto',
    },
  },

  serviceInfoBox: {
    gridArea: 'info',
  },

  titleBox: {
    marginBottom: '40px',
  },

  title: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '1rem',
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      width: '100%',
      height: '3px',
      background: [theme.palette.gradientBlue.main],
      marginTop: '1rem',
    },
  },

  btn: {
    margin: '.5rem 1rem',
    textDecoration: 'none',
    fontSize: '1.5rem',
    color: 'inherit',

    '&:hover': {
      fontWeight: 700,
    },

    '&.accent': {
      color: [theme.palette.primary.secondary],
      fontWeight: 700,
    },
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      position: 'relative',
      zIndex: 5,
    },

    serviceBox: {
      display: 'grid',
      gridTemplateAreas: `'image info'`,
      gridTemplateColumns: '4fr 7fr',
      justifyContent: 'space-between',
      alignItems: 'center',
      gridGap: '2rem',

      '&.reverse': {
        gridTemplateAreas: `'info image'`,
        gridTemplateColumns: '7fr 4fr',
      },
    },

    

    imageBox: {
  
      '& svg': {
        width: 'auto',
        height: 'auto',
      },
    },

    titleBox: {
      '& .subtitle': {
        width: '80%'
      },
    },

    title: {
      textAlign: 'left',
      '&:after': {
        width: '450px',
      },
    },

    titleRev: {
      textAlign: 'right',

      '&:after': {
        margin: '1rem 0 0 auto'
      },
    },

    subtitleRev: {
      textAlign: 'right',
      margin: '0 0 0 auto',
    },

    buttonsBox: {
      columnCount: '2',

      '&.single': {
        columnCount: '1',
      },
    },

    linkBoxRev: {
      justifyContent: 'flex-end',
    },

  }
}))

const ServicesList = () => {

  const classes = useStyles()

  const devBtns = [
    {
      name: 'Лендинг Пейдж',
      link: './web-dev/landing',
    },
    {
      name: 'Небольшой сайт',
      link: './web-dev/small-site',
    },
    {
      name: 'Корпоративный сайт',
      link: './web-dev/corp-site',
    },
    {
      name: 'Сайт-квиз',
      link: './web-dev/quiz-site',
    },
    {
      name: 'Интернет-магазин',
      link: './web-dev/internet-shop',
    },
  ]

  const advBtns = [
    {
      name: 'Узнать подробнее',
      link: './marketing',
    },
  ]

  const designBtns = [
    {
      name: 'Узнать подробнее',
      link: './design',
    },
  ]

  const automatBtns = [
    {
      name: 'Узнать подробнее',
      link: './#',
    },
  ]

  return (
    <Box id="services-list" component="section" className={classes.wrapper} mb={5}>
      <Box className={classes.gridWrap}>
        <Container maxWidth="xl">
          <Box className={classes.serviceBox}>
            <Box className={classes.imageBox}>
              <SiteDev />
            </Box>
            <Box className={classes.serviceInfoBox}>
              <Box className={classes.titleBox}>
                <Typography variant="h2" className={classes.title}>разработка сайтов</Typography>
                <Typography variant="body1" className="subtitle">Профессиональный, продуманный дизайн сайта в стеке с современными технологиями разработки будут привлекать и вдохновлять ваших клиентов</Typography>
                
              </Box>
              <Box className={classes.moreInfo} mt={2}>
                  <Box display="flex" alignItems="center">
                    <ArrowRight />
                    <Link className={`${classes.btn} accent`} to="./web-dev">Подробнее о разработке сайтов</Link>
                  </Box>
              </Box>
              <Box className={classes.buttonsBox}>
                {
                  devBtns.map((btn, idx) => (
                    <Box display="flex" alignItems="center" key={`${btn.name}-${idx}`}>
                      <ArrowRight />
                      <Link className={classes.btn} to={btn.link}>{btn.name}</Link>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={`${classes.gridWrap} gray`}>
        <Container maxWidth="xl">
          <Box className={`${classes.serviceBox} reverse`}>
            <Box className={classes.imageBox}>
              <AdvertImage />
            </Box>
            <Box className={`${classes.serviceInfoBox}`}>
              <Box className={classes.titleBox}>
                <Typography variant="h2" className={`${classes.title} ${classes.titleRev}`}>интернет реклама</Typography>
                <Typography variant="body1" className={`${classes.subtitleRev} subtitle`}>Проведем анализ ваших конкурентов, выделим сильные стороны вашего бизнеса, соберем семантическое ядро и настроим рекламу в Яндекс и Google</Typography>
              </Box>
              <Box className={`${classes.buttonsBox} single`}>
                {
                  advBtns.map((btn, idx) => (
                    <Box className={classes.linkBoxRev} display="flex" alignItems="center" key={`${btn.name}-${idx}`}>
                      <Link className={classes.btn} to={btn.link}>{btn.name}</Link>
                      <ArrowRight />
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.gridWrap}>
        <Container maxWidth="xl">
          <Box className={classes.serviceBox}>
            <Box className={classes.imageBox}>
              <DesignImage />
            </Box>
            <Box className={classes.serviceInfoBox}>
              <Box className={classes.titleBox}>
                <Typography variant="h2" className={classes.title}>дизайн и проектирование</Typography>
                <Typography variant="body1" className="subtitle">Разработаем для вас логотип и фирменный стиль. При необходимости готовы предоставить полный спектр услуг по графическому и web-дизайну</Typography>
              </Box>
              <Box className={`${classes.buttonsBox} single`}>
                {
                  designBtns.map((btn, idx) => (
                    <Box display="flex" alignItems="center" key={`${btn.name}-${idx}`}>
                      <ArrowRight />
                      <Link className={classes.btn} to={btn.link}>{btn.name}</Link>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={`${classes.gridWrap} gray`}>
        <Container maxWidth="xl">
          <Box className={`${classes.serviceBox} reverse`}>
            <Box className={classes.imageBox}>
              <AutomatImage />
            </Box>
            <Box className={`${classes.serviceInfoBox}`}>
              <Box className={classes.titleBox}>
                <Typography variant="h2" className={`${classes.title} ${classes.titleRev}`}>автоматизация</Typography>
                <Typography variant="body1" className={`${classes.subtitleRev} subtitle`}>Автоматизируем ваш сайт, что позволит вам не только самостоятельно управлять содержанием сайта, но  решать ряд задач по заранее разработанному алгоритму</Typography>
              </Box>
              <Box className={`${classes.buttonsBox} single`}>
                {
                  automatBtns.map((btn, idx) => (
                    <Box className={classes.linkBoxRev} display="flex" alignItems="center" key={`${btn.name}-${idx}`}>
                      <Link className={classes.btn} to={btn.link}>{btn.name}</Link>
                      <ArrowRight />
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default ServicesList