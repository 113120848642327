import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import ArrowIcon from '../../svg/arrowRightGrdnt.svg'
import { BgImage } from 'gbimage-bridge';
import { getImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({

  wrapper: {
    height: '80vh',
    color: '#fff',
    alignItems: 'flex-end',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: [theme.palette.gradientDark.main60],
    },
  },

  contentBox: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 3,
  },

  title: {

    textTransform: 'uppercase',

    '& span': {
      color: [theme.palette.primary.main],
    //   backgroundColor: [theme.palette.primary.main],
    //   backgroundImage: [theme.palette.gradientBlue.main],
    //   color: 'transparent',
    // '-webkit-background-clip': 'text',
    // '-webkit-text-fill-color': 'transparent',
    },
  },

  subtitle: {
    fontSize: '1.2rem',
    lineHeight: 1.2,
    fontWeight: 400,
  },

  // '@media (min-width: 600px)': {
  //   wrapper: {
  //     minHeight: '820px',
  //   },
  // },

  // '@media (min-width: 768px)': {
  //   wrapper: {
  //     minHeight: '1024px',
  //   },
  // },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      alignItems: 'center',
      zIndex: 2,
      minHeight: '50vh',
      height: '900px',

      '&:before': {
        content: 'none',
      },
    },

    subtitle: {
      fontSize: '1.5rem',
    },

    contentBox: {
      textAlign: 'left',
    }
  },

}))

const MainScreen = () => {

  const { desktopImage } = useStaticQuery(
    graphql`
      query {
        desktopImage: file(relativePath: { eq: "background-1.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const pluginImage = getImage(desktopImage)

  const classes = useStyles()

  return (
    <BgImage image={pluginImage} >
      <Box className={classes.wrapper} display="flex" py={3}>
          <Container maxWidth="xl">
            <Box className={classes.contentBox}>
              <Grid
                container
              >
                <Grid item md={6}>
                  <Box className={classes.titleBox} mb={2}>
                    <Typography variant="h1" className={classes.title}>предоставляем различные услуги в сфере <span>интернет-маркетинга</span></Typography>
                  </Box>
                  <Box 
                    className={classes.subtitleBox}
                    mb={{xs: 5, md: 8}}
                  >
                    <h2 className={classes.subtitle}>Решаем любые задачи для вашего бизнеса</h2>
                  </Box>
                  <Box className={classes.btnBox}>
                    <Button href="#services-list" variant="outlined" color="inherit" endIcon={<ArrowIcon />} size="large">подробнее</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
      </Box>
    </BgImage>
  )
}

export default MainScreen
